/* 布局 */
//前台
import Layout from "@views/layout";

const stuuserRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "stuuser/list",
            name: "STUUSER",
            meta: {
                title: "学生管理",
            },
            component: () => import("@views/business/stuuser/index.vue"),
        },
        {
            path: "stuuser/studentdetails/:id",
            name: "STUUSER_STUDENTDETAILS",
            meta: {
                title: "学生详情",
            },
            component: () => import("@views/business/stuuser/studentdetails.vue"),
        },
    ],
};

export default stuuserRouter;
