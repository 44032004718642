/* 布局 */
//前台
import Layout from "@views/layout";

const plavipRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        
        {
            path: "plavip/list",
            name: "PLAVIP_LIST",
            meta: {
                title: "会员付费",
            },
            component: () => import("@views/business/plavip/index.vue"),
        },
        {
            path: "plavip/add",
            name: "PLAVIP_ADD",
            meta: {
                title: "新增会员付费",
                parentPath: '/business/plavip/list',
                operate: true,
            },
            component: () => import("@views/business/plavip/form.vue"),
        },
        {
            path: "plavip/edit",
            name: "PLAVIP_EDIT",
            meta: {
                title: "编辑会员付费",
                parentPath: '/business/plavip/list',
                operate: true,
            },
            component: () => import("@views/business/plavip/form.vue"),
        },
        
        ],


};

export default plavipRouter;
