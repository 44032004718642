import API from "@utils/request";
import { PAGE_SIZE } from "@config";

const ADRESS = "/group/";
const ADRESSITEM = "/groupitem/";


/**
 * 获取配置列表（分页）
 * @param {object} params
 * @param {object} pages
 */
export async function getGroupList(params, pages) {
  try {

    return await API.get(ADRESS + "list", {
      params: {
        ...params,
        ...pages,
      },
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取配置详情
 * @param {number} id
 */
export async function getGroupInfo(id) {
  try {
    return await API.post(ADRESS + "info", {
      id: {
        id,
      },
    });
  } catch (error) {
    return error;
  }
}



/**
 * 编辑配置
 * @param {object} params
 */
export async function editGroup(params) {
  try {
    return await API.post(ADRESS + "edit", params);
  } catch (error) {
    return error;
  }
}


/**
 * 新增配置
 * @param {object} params
 */
export async function addGroup(params) {
  try {
    return await API.post(ADRESS + "add", params);
  } catch (error) {
    return error;
  }
}

/**
 * 刪除配置
 * @param {number} id
 */
export async function deleteGroup(id) {
  try {
    return await API.post(ADRESS + "delete", {
      id: {
        id,
      },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取配置参数组合
 * @param {object} code
 */
 export async function getGroupData(code) {
  try {
    return await API.post(ADRESS + "getgroupdata", {
      code: code,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 生成配置缓存文件
 */
 export async function createGroupCash() {
  try {
    return await API.post(ADRESS + "createcash", {});
  } catch (error) {
    return error;
  }
}


/**
 * 获取配置项列表（分页）
 * @param {object} params
 * @param {object} pages
 */
 export async function getGroupItemList(params, pages) {
  try {

    return await API.get(ADRESSITEM + "list", {
      params: {
        ...params,
        ...pages,
      },
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取配置项详情
 * @param {number} id
 */
export async function getGroupItemInfo(id) {
  try {
    return await API.post(ADRESSITEM + "info", {
      id: {
        id,
      },
    });
  } catch (error) {
    return error;
  }
}



/**
 * 编辑配置项
 * @param {object} params
 */
export async function editGroupItem(params) {
  try {
    return await API.post(ADRESSITEM + "edit", params);
  } catch (error) {
    return error;
  }
}


/**
 * 新增配置项
 * @param {object} params
 */
export async function addGroupItem(params) {
  try {
    return await API.post(ADRESSITEM + "add", params);
  } catch (error) {
    return error;
  }
}

/**
 * 刪除配置项
 * @param {number} id
 */
export async function deleteGroupItem(id) {
  try {
    return await API.post(ADRESSITEM + "delete", {
      id: {
        id,
      },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 修改配置项狀態
 * @param {number} id
 */
export async function disableGroupItem(id, status) {
  try {
    return await API.post(ADRESSITEM + "disable", {
      id: id,
      status: status,
    });
  } catch (error) {
    return error;
  }
}
/**
 * 获取配置项键值对
 * @param {object} key
 * @param {object} value
 */
 export async function getGroupItemKeyVal(key, value) {
  try {
    return await API.post(ADRESSITEM + "getkeyval", {
      key: key,
      value: value,
    });
  } catch (error) {
    return error;
  }
}



