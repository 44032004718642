/* 布局 */
//前台
import Layout from "@views/layout";

const plaparcurriculumclassRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "plastatistic/analysislist",
            name: "PLASTATISTIC_ANALYSISLIST",
            meta: {
                title: "数据统计",
            },
            component: () => import("@views/business/plastatistic/analysislist.vue"),
        },
        {
            path: "plastatistic/resourcelist",
            name: "PLASTATISTIC_RESOURCELIST",
            meta: {
                title: "资源建设",
            },
            component: () => import("@views/business/plastatistic/resourcelist.vue"),
        },
        {
            path: "plastatistic/activelist",
            name: "PLASTATISTIC_ACTIVELIST",
            meta: {
                title: "活跃度分析",
            },
            component: () => import("@views/business/plastatistic/activelist.vue"),
        },
    ],
};

export default plaparcurriculumclassRouter;
