/* 布局 */
//前台
import Layout from "@views/layout";

const plaboardlibraryRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "plaboardlibrary/list",
        name: "PLABOARDLIBRARY",
        meta: {
            title: "手写板试用库",
        },
        component: () => import("@views/business/plaboardlibrary/index.vue"),
    },
        ],
};

export default plaboardlibraryRouter;
