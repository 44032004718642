const state = {
    branchListSearch: [], //部门列表（筛选用）
    menuListSearch: [], //菜单列表（筛选用）
};

let hierarchy = 0;
let branchList = [];
let menuList = [];

function mapData(data, type) {
    data.map(item => item.hierarchy = hierarchy)
    data.forEach((item) => {
        switch (type) {
            case 'branch':
                branchList.push(item);
                break;
            case 'menu':
                menuList.push(item);
                break;
            default:
                break;
        }
        if (item.children) {
            hierarchy = item.hierarchy + 1
            mapData(item.children, type);
        }
    });
}

const mutations = {
    setStateNewValue: (state, obj) => {
        state[obj.key] = obj.value;
    },
};

const actions = {
    /** 部门列表(下拉树状数据) */
    async getBranchList({
        commit
    }) {
        let data = [{
                id: 1,
                name: "总经办",
                remarke: "备注文本xxxxxxxxxxxxxxxx",
                creatTime: 1648792694,
                sort: 1,
                pid: 0,
            },
            {
                id: 2,
                name: "销售",
                remarke: "备注文本xxxxxxxxxxxxxxxx",
                creatTime: 1648792694,
                sort: 1,
                pid: 0,
            },
            {
                id: 3,
                name: "技术部",
                remarke: "备注文本xxxxxxxxxxxxxxxx",
                creatTime: 1648792694,
                sort: 1,
                pid: 0,
                children: [{
                        id: 31,
                        name: "前端",
                        remarke: "备注文本xxxxxxxxxxxxxxxx",
                        creatTime: 1648792694,
                        sort: 1,
                        pid: 3,
                    },
                    {
                        id: 32,
                        name: "Java",
                        remarke: "备注文本xxxxxxxxxxxxxxxx",
                        creatTime: 1648792694,
                        sort: 1,
                        pid: 3,
                    },
                    {
                        id: 33,
                        name: "PHP",
                        remarke: "备注文本xxxxxxxxxxxxxxxx",
                        creatTime: 1648792694,
                        sort: 1,
                        pid: 3,
                    },
                    {
                        id: 34,
                        name: "测试",
                        remarke: "备注文本xxxxxxxxxxxxxxxx",
                        creatTime: 1648792694,
                        sort: 1,
                        pid: 3,
                    },
                ],
            },
            {
                id: 4,
                name: "产品",
                remarke: "备注文本xxxxxxxxxxxxxxxx",
                creatTime: 1648792694,
                sort: 1,
                pid: 0,
            },
        ];
        /** 请求接口数据 */
        branchList = [];
        mapData(data, 'branch');
        await commit("setStateNewValue", {
            key: "branchListSearch",
            value: branchList,
        });
    },
    /** 菜单列表(下拉树状数据) */
    async getMenuList({
        commit
    }) {
        let data = [{
                id: 1,
                label: "控制台",
                type: "menu",
                routerLink: "/homepage",
                routerName: "HOMEPAGE",
                sort: 1,
            },
            {
                id: 2,
                label: "系统设置",
                children: [{
                        id: 21,
                        pid: 2,
                        label: "用户管理",
                        type: "menu",
                        routerLink: "/system/user",
                        routerName: "USER",
                        sort: 1,
                        children: [{
                                id: 211,
                                pid: 21,
                                label: "添加用户",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                            {
                                id: 212,
                                pid: 21,
                                label: "编辑用户",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                            {
                                id: 213,
                                pid: 21,
                                label: "重置密码",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                            {
                                id: 214,
                                pid: 21,
                                label: "删除用户",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                        ],
                    },
                    {
                        id: 22,
                        pid: 2,
                        label: "角色管理",
                        type: "menu",
                        routerLink: "/system/role",
                        routerName: "ROLE",
                        sort: 1,
                        children: [{
                                id: 221,
                                pid: 22,
                                label: "添加角色",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                            {
                                id: 222,
                                pid: 22,
                                label: "编辑角色",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                            {
                                id: 223,
                                pid: 22,
                                label: "菜单配置",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                            {
                                id: 224,
                                pid: 22,
                                label: "权限分配",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                            {
                                id: 225,
                                pid: 22,
                                label: "删除角色",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                        ],
                    },
                    {
                        id: 23,
                        pid: 2,
                        label: "部门管理",
                        type: "menu",
                        routerLink: "/system/branch",
                        routerName: "BRANCH",
                        sort: 1,
                        children: [{
                                id: 231,
                                pid: 23,
                                label: "添加部门",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                            {
                                id: 232,
                                pid: 23,
                                label: "编辑部门",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                            {
                                id: 234,
                                pid: 23,
                                label: "删除部门",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                        ],
                    },
                    {
                        id: 24,
                        pid: 2,
                        label: "岗位管理",
                        type: "menu",
                        routerLink: "/system/station",
                        routerName: "STATION",
                        sort: 1,
                        children: [{
                                id: 241,
                                pid: 24,
                                label: "添加岗位",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                            {
                                id: 242,
                                pid: 24,
                                label: "编辑岗位",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                            {
                                id: 244,
                                pid: 24,
                                label: "删除岗位",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                        ],
                    },
                    {
                        id: 25,
                        pid: 2,
                        label: "权限管理",
                        type: "menu",
                        routerLink: "/system/authority",
                        routerName: "AUTHORITY",
                        sort: 1,
                        children: [{
                                id: 251,
                                pid: 25,
                                label: "添加权限",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                            {
                                id: 252,
                                pid: 25,
                                label: "编辑权限",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                            {
                                id: 254,
                                pid: 25,
                                label: "删除权限",
                                type: "button",
                                routerLink: "",
                                routerName: "",
                                sort: 1,
                            },
                        ],
                    },
                ],
            },
            {
                id: 3,
                label: "配置管理",
            },
        ];
        /** 请求接口数据 */
        menuList = [];
        mapData(data, 'menu');
        await commit("setStateNewValue", {
            key: "menuListSearch",
            value: menuList,
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};