/* 布局 */
//前台
import Layout from "@views/layout";

const plapurchaseclassRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "plapurchaseclass/list",
        name: "PLAPURCHASECLASS",
        meta: {
            title: "采购单据类型",
        },
        component: () => import("@views/business/plapurchaseclass/index.vue"),
    },
        ],
};

export default plapurchaseclassRouter;
