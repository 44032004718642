/* 布局 */
//前台
import Layout from "@views/layout";

const placoursewareexamineRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "placoursewareexamine/list",
        name: "PLACOURSEWAREEXAMINE",
        meta: {
            title: "学校课件审核",
        },
        component: () => import("@views/business/placoursewareexamine/index.vue"),
    },
        ],
};

export default placoursewareexamineRouter;
