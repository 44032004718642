import Vue from "vue";
import "normalize.css/normalize.css"; // 替代CSS重置
import "@utils/element-ues"; // 按需引入ElementUI,若有缺少，自行添加
import "@assets/styles/element-variables.scss";
import "@assets/styles/public.scss"; // 全局引用样式

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { API_ROOT } from "@config";

import './permission' // 权限控制

import _ from "lodash"; // JavaScript 实用工具库

import * as filters from "@/filters"; // 全局过滤器

import * as directives from "./directives"; // 全局过指令
import * as echarts from "echarts"; //图表

//ueditor
import config from "../lib/ueditor/ueditor.config.js";
import "../lib/ueditor/ueditor.all.min.js";
import "../lib/ueditor/lang/zh-cn/zh-cn.js";


//print
import print from "print-js";


// 处理自定义组件全局注册
const files = require.context('./components/try', true, /\.vue$/)
files.keys().forEach((routerPath) => {
  const componentName = routerPath.replace(/^\.\/(.*)\/index\.\w+$/, '$1')
  if (componentName.indexOf('/') === -1) {
    const value = files(routerPath)
    Vue.component('try' + componentName.replace(/([A-Z])/g, '-$1').toLowerCase(), value.default)
  }
}, {});


/* 富文本*/
import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'
Vue.prototype.$tinymce = tinymce;
Vue.use(VueTinymce);
/* 二次封装  */
import vueTinymceEditor from '@/components/tinymceEditor';
Vue.component('TinymceEditor', vueTinymceEditor);

/* 判断数据是否存在、是否为空（空对象 || 空数组） */
Vue.prototype.$isEmpty = function (data) {
  if (!data) return true;
  return data instanceof Array && !data.length || Object.keys(data).length == 0 || data + '' == '';
};
/* 上传配置 */
Vue.prototype.$upload = {
  header: { accessToken: sessionStorage.getItem("token") },
  imgAccept: ".jpg,.jpeg,.png",
  imgAction: API_ROOT + `/tool/uploadimg/`, // 图片上传地址
  fileAction: API_ROOT + `/tool/uploadFile`, // 文件上传地址
  fileAction2: `${API_ROOT}/protal/tool/inportFile`, // 文件上传地址
};
/* 文件上传前处理 */
Vue.prototype.$beforFileUpload = (file, fileTypes) => {
  const videoType = ["mp4", "avi", "wmv", "mpeg"]; // 视频文件类型
  let fileType = file.name.split(".");
  fileType = fileType[fileType.length - 1];
  const isRightFormat = fileTypes.indexOf(fileType) != -1;
  const isVideo = videoType.indexOf(fileType) != -1;
  const isSize = file.size / 1024 / 1024 < 200; // 其他文件大小
  const isVideoSize = file.size / 1024 / 1024 / 1024 < 3; // 视频文件大小

  if (!isRightFormat) {
    let typeMes = fileTypes.map((item) => `.${item}`);
    Message.error(`请上传规定的文件格式（${typeMes.join(",")}）`);
    return false;
  }
  if (isVideo && !isVideoSize) {
    Message.error(`上传视频大小不能超过 3G!`);
    return false;
  }
  if (!isVideo && !isSize) {
    Message.error(`上传文件大小不能超过 200MB!`);
    return false;
  }
  return (isVideo && isVideoSize) || (!isVideo && isSize);
};
/* 图片上传前处理 */
Vue.prototype.$beforImgUpload = (file) => {
  const imgType = ["jpg", "jpeg", "png"]; // 图片文件类型
  let fileType = file.name.split(".");
  fileType = fileType[fileType.length - 1];
  const isImg = imgType.indexOf(fileType) != -1;
  const isSize = file.size / 1024 / 1024 < 10;
  if (!isImg) {
    Message.error("请上传规定的图片格式（.jpg,.jpeg,.png）");
    return false;
  }
  if (!isSize) {
    Message.error("上传图片大小不能超过 10MB!");
    return false;
  }

  return isImg && isSize;
};

//screenfull
import screenfull from "screenfull";

Vue.prototype.config = config;

// lodash挂载
Vue.prototype.$screenfull = screenfull;
Vue.prototype.$echarts = echarts; // 全局引用图表
Vue.prototype.$_ = _; // 

// 注册全局过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});


/* 全局引用自定义组件 */
import richSelect from "@comp/richSelect";
Vue.component("richSelect", richSelect);

// 注册全局过指令
Object.keys(directives).forEach((key) => {
  Vue.use(directives[key]);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

/** 权限指令**/
Vue.directive('prikey', {
  inserted(el, binding) {
    //获取缓存的按钮数组
    const prilist = sessionStorage.getItem('prilist');
    const str = binding.value;
    var priarr = prilist.split(',');

    // 不存在则隐藏
    if (priarr === null || priarr === undefined || priarr.findIndex(item => item == str) === -1) {
      el.parentNode.removeChild(el);
      //el.style.display = 'none';
    }
  }
});

/* 文件链接下载 */
Vue.prototype.$fileDownload = (url) => {
  let name = url.split("/");
  name = name[name.length - 1].split(".")[0];
  // 创建一个a标签
  let link = document.createElement("a");
  // 给a标签设置id
  link.id = "downloadLink";
  // 将a标签隐藏
  link.style.display = "none";
  // 给a标签添加下载链接
  link.href = url;
  // 给a标签添加一个download属性
  link.setAttribute("download", name);
  // 将创建的a标签加入到body的尾部
  document.body.appendChild(link);
  // 执行a标签
  link.click();
  // 获取a标签dom
  let node = document.getElementById("downloadLink");
  // 删除对应dom节点
  document.body.removeChild(node);
};


