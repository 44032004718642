/* 布局 */
//前台
import Layout from "@views/layout";

const plainformationRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "plainformation/list",
        name: "PLAINFORMATION",
        meta: {
            title: "资讯文章",
        },
        component: () => import("@views/business/plainformation/index.vue"),
    },
        ],
};

export default plainformationRouter;
