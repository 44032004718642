/* 布局 */
//前台
import Layout from "@views/layout";

const sysdataimagesRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "sysdataimages/list",
        name: "SYSDATAIMAGES",
        meta: {
            title: "验证码背景图库",
        },
        component: () => import("@views/business/sysdataimages/index.vue"),
    },
        ],
};

export default sysdataimagesRouter;
