/* 布局 */
//前台
import Layout from "@views/layout";

const schclassscheduleRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "schclassschedule/list",
            name: "SCHCLASSSCHEDULE",
            meta: {
                title: "学校课程表",
            },
            component: () => import("@views/business/schclassschedule/index.vue"),
        },
    ],
};

export default schclassscheduleRouter;
