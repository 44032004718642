/* 布局 */
//前台
import Layout from "@views/layout";

const plaorderrefundtypeRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "plaorderrefundtype/list",
            name: "PLAORDERREFUNDTYPE",
            meta: {
                title: "平台订单退款类型",
            },
            component: () => import("@views/business/plaorderrefundtype/index.vue"),
        },
    ],
};

export default plaorderrefundtypeRouter;
