/* 布局 */
//前台
import Layout from "@views/layout";

const questionRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "question/list",
            name: "QUESTION",
            meta: {
                title: "题目管理",
            },
            component: () => import("@views/business/question/list.vue"),
            
        },
        {
            path: "add/:refid",
            name: "QUESTION_ADD",
            meta: {
                title: "新增题目",
                parentPath: '/business/question/list',
                operate: true,
            },
            component: () => import("@views/business/question/form.vue"),
        },
        {
            path: "edit/:id",
            name: "QUESTION_EDIT",
            meta: {
                title: "编辑题目",
                parentPath: '/business/question/list',
                operate: true,
            },
            component: () => import("@views/business/question/form.vue"),
        },
    ],
};

export default questionRouter;
