/* 布局 */
//前台
import Layout from "@views/layout";

const plawarehouseclassRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "plawarehouseclass/list",
            name: "PLAWAREHOUSECLASS",
            meta: {
                title: "仓库分类管理",
            },
            component: () => import("@views/business/plawarehouseclass/index.vue"),
        },
    ],
};

export default plawarehouseclassRouter;
