/* 布局 */
//前台
import Layout from "@views/layout";

const plaorderrefundRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
            {
            path: "plaorderrefund",
            name: "PLAORDERREFUND",
            meta: {
                title: "退款订单管理",
                sort: 1,
            },
            component: () => import("@views/business/plaorderrefund/index.vue"),
            redirect: {
                name: "PLAORDERREFUND_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "PLAORDERREFUND_LIST",
                    meta: {
                        title: "退款订单管理",
                        sort: 2,
                    },
                    component: () => import("@views/business/plaorderrefund/list.vue"),
                },
                                {
                    path: "add/:refid",
                    name: "PLAORDERREFUND_ADD",
                    meta: {
                        title: "新增退款订单管理",
                        parentPath: '/business/plaorderrefund/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plaorderrefund/form.vue"),
                },
                                                {
                    path: "edit/:id",
                    name: "PLAORDERREFUND_EDIT",
                    meta: {
                        title: "编辑退款订单管理",
                        parentPath: '/business/plaorderrefund/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plaorderrefund/form.vue"),
                },
                                        ],
    },


        ],
};

export default plaorderrefundRouter;
