/* 布局 */
//前台
import Layout from "@views/layout";

const plaparcurriculumchapterRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "plaparcurriculumchapter/list/:id",
            name: "PLAPARCURRICULUMCHAPTER",
            meta: {
                title: "家长课程章节",
            },
            component: () => import("@views/business/plaparcurriculumchapter/index.vue"),
        },
    ],
};

export default plaparcurriculumchapterRouter;
