/* 布局 */
//前台
import Layout from "@views/layout";

const plaquestionRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "plaquestion",
            name: "PLAQUESTION",
            meta: {
                title: "题目管理",
                sort: 1,
            },
            component: () => import("@views/business/plaquestion/index.vue"),
            redirect: {
                name: "PLAQUESTION_LIST",
            },
            children: [
                {
                    path: "list/:type/:id",
                    name: "PLAQUESTION_LIST",
                    meta: {
                        title: "题目管理",
                        sort: 2,
                    },
                    component: () => import("@views/business/plaquestion/list.vue"),
                },
                {
                    path: "add/:refid",
                    name: "PLAQUESTION_ADD",
                    meta: {
                        title: "新增题目管理",
                        parentPath: '/business/plaquestion/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plaquestion/form.vue"),
                },
                {
                    path: "edit/:id",
                    name: "PLAQUESTION_EDIT",
                    meta: {
                        title: "编辑题目管理",
                        parentPath: '/business/plaquestion/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plaquestion/form.vue"),
                },
            ],
        },


    ],
};

export default plaquestionRouter;
