/* 布局 */
//前台
import Layout from "@views/layout";

const plaordertypeRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "plaordertype/list",
        name: "PLAORDERTYPE",
        meta: {
            title: "订单类型",
        },
        component: () => import("@views/business/plaordertype/index.vue"),
    },
        ],
};

export default plaordertypeRouter;
