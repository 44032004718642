/* *********************** 正式环境 ********************** */
export const API_ROOT = "https://api.zytycd.com/";
// export const API_ROOT = "/api/";

/* *********************** 测试环境 ********************** */
// export const API_ROOT = "/api/admin/";

// 系统标题
export const TITLE = "育材地平台端";

// 系统中文标题
export const CN_LOGINTITLE = "育材地平台端";

// 生产环境和测试环境
// export const DEBUG = process.env.NODE_ENV !== "production";
export const DEBUG = true;

// 设置请求的超时时间   单位毫秒
export const REQUEST_TIMEOUT = 30000*1000;

// 文件服务器地址
// export const FILE_ROOT = "http://192.168.2.123:1026"; // gq
// export const FILE_ROOT = "https://api.zytycd.com/"; // wyp
export const FILE_ROOT = "https://yucaidi.oss-cn-chengdu.aliyuncs.com/"; // oss

// 一页显示的条目
export const PAGE_SIZE = 10;

// 当前版本
export const VERSION = "1.0.0";
