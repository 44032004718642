/* 布局 */
//前台
import Layout from "@views/layout";

const memberRouter = {
    path: "/version",
    component: Layout,
    redirect: {
        name: "VERSION"
    },
    children: [
        {
            path: "systeam",
            name: "SYSTEAM",
            meta: {
                title: "系统版本管理",
            },
            component: () => import("@views/version/systeam.vue"),
        },
        {
            path: "model",
            name: "MODEL",
            meta: {
                title: "模块版本管理",
            },
            component: () => import("@views/version/model.vue"),
        },
    ],
};

export default memberRouter;
