/* 布局 */
//前台
import Layout from "@views/layout";

const plastucurriculumRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "plastucurriculum/list",
        name: "PLASTUCURRICULUM",
        meta: {
            title: "学生课程",
        },
        component: () => import("@views/business/plastucurriculum/index.vue"),
    },
        ],
};

export default plastucurriculumRouter;
