<template>
  <div class="demo-image__preview">
    <el-link class="el-icon-document" v-for="item in tValue" :key="item"  :href="item"  target="_blank">{{item}}</el-link>
  </div>
</template>
<script>
import { Empty } from 'element-ui'
export default {
  name: 'TryFile',
  props: {
    value: {// 绑定的值
      type: String,
      default: undefined
    },

  },
  data() {
    return {
      loading: false,
      tValue: this.value && this.value != "" ? this.value.split(',') : [],
    }
  },
  watch: {
    value(n, o) {
      if (n) {
        this.tValue = n.split(",");
      }
    },
    
  },
  created() {
  },
  methods: {
    /**
    * 从数组中移除空值
    * @array {Array} 
    */
    trimSpace(array) {
      for (var i = 0; i < array.length; i++) {
        //这里为过滤的值
        if (array[i] == "" || array[i] == null || typeof (array[i]) == "undefined" || array[i] == '') {
          array.splice(i, 1);
          i = i - 1;
        }
      }
      return array;
    }
  }
}
</script>
