/* 布局 */
//前台
import Layout from "@views/layout";

const syssubjectRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "syssubject/list",
        name: "SYSSUBJECT",
        meta: {
            title: "科目管理",
        },
        component: () => import("@views/business/syssubject/index.vue"),
    },
        ],
};

export default syssubjectRouter;
