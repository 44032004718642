/* 布局 */
//前台
import Layout from "@views/layout";

const plaboardschoolRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "plaboardschool/list",
        name: "PLABOARDSCHOOL",
        meta: {
            title: "手写板学校库",
        },
        component: () => import("@views/business/plaboardschool/index.vue"),
    },
        ],
};

export default plaboardschoolRouter;
