/* 布局 */
//前台
import Layout from "@views/layout";

const plagoodsRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "plagoods",
            name: "PLAGOODS",
            meta: {
                title: "商品表",
                sort: 1,
            },
            component: () => import("@views/business/plagoods/index.vue"),
            redirect: {
                name: "PLAGOODS_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "PLAGOODS_LIST",
                    meta: {
                        title: "商品管理",
                        sort: 2,
                    },
                    component: () => import("@views/business/plagoods/list.vue"),
                },
                {
                    path: "add/:refid",
                    name: "PLAGOODS_ADD",
                    meta: {
                        title: "新增商品",
                        parentPath: '/business/plagoods/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plagoods/form.vue"),
                },
                {
                    path: "edit/:id",
                    name: "PLAGOODS_EDIT",
                    meta: {
                        title: "编辑商品",
                        parentPath: '/business/plagoods/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plagoods/form.vue"),
                },
            ],
        },


    ],
};

export default plagoodsRouter;
