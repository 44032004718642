/* 布局 */
//前台
import Layout from "@views/layout";

const placheckgoodsRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "placheckgoods",
            name: "PLACHECKGOODS",
            meta: {
                title: "盘点任务管理",
                sort: 1,
            },
            component: () => import("@views/business/placheckgoods/index.vue"),
            redirect: {
                name: "PLACHECKGOODS_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "PLACHECKGOODS_LIST",
                    meta: {
                        title: "盘点任务管理",
                        sort: 2,
                    },
                    component: () => import("@views/business/placheckgoods/list.vue"),
                },
                {
                    path: "add/:refid",
                    name: "PLACHECKGOODS_ADD",
                    meta: {
                        title: "新增盘点任务管理",
                        parentPath: '/business/placheckgoods/list',
                        operate: true,
                    },
                    component: () => import("@views/business/placheckgoods/form.vue"),
                },
                {
                    path: "edit/:id",
                    name: "PLACHECKGOODS_EDIT",
                    meta: {
                        title: "编辑盘点任务管理",
                        parentPath: '/business/placheckgoods/list',
                        operate: true,
                    },
                    component: () => import("@views/business/placheckgoods/form.vue"),
                },
            ],
        },
        {
            path: "placheckgoods/mylist",
            name: "PLACHECKGOODS",
            meta: {
                title: "我的盘点任务",
            },
            component: () => import("@views/business/placheckgoods/mylist.vue"),
        },

    ],
};

export default placheckgoodsRouter;
