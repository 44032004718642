/* 布局 */
//前台
import Layout from "@views/layout";

const plaquestionbankRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "plaquestionbank",
            name: "PLAQUESTIONBANK",
            meta: {
                title: "题库中心",
                sort: 1,
            },
            component: () => import("@views/business/plaquestionbank/index.vue"),
            redirect: {
                name: "PLAQUESTIONBANK_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "PLAQUESTIONBANK_LIST",
                    meta: {
                        title: "题库中心",
                        sort: 2,
                    },
                    component: () => import("@views/business/plaquestionbank/list.vue"),
                },
                {
                    path: "add/:refid",
                    name: "PLAQUESTIONBANK_ADD",
                    meta: {
                        title: "新增题库中心",
                        parentPath: '/business/plaquestionbank/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plaquestionbank/form.vue"),
                },
                {
                    path: "edit/:id",
                    name: "PLAQUESTIONBANK_EDIT",
                    meta: {
                        title: "编辑题库中心",
                        parentPath: '/business/plaquestionbank/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plaquestionbank/form.vue"),
                },
            ],
        },
        {
            path: "plaquestionbank/home",
            name: "PLAQUESTIONBANK_HOME",
            meta: {
                title: "题库中心",
            },
            component: () => import("@views/business/plaquestionbank/home.vue"),
        },

    ],
};

export default plaquestionbankRouter;
