/* 布局 */
//前台
import Layout from "@views/layout";

const paruserRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "paruser/list",
            name: "PARUSER",
            meta: {
                title: "家长管理",
            },
            component: () => import("@views/business/paruser/index.vue"),
        },
        {
            path: "paruser/par_order/:id",
            name: "PARUSER_ORDER",
            meta: {
                title: "家长订单",
            },
            component: () => import("@views/business/paruser/paruser_order.vue"),
        },
    ],
};

export default paruserRouter;
