<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量

.side-bar {
  position: relative;
  background: $menuBg;

  .logo {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
    color: $menuTextColor;
    cursor: pointer;

    img {
      width: 30px;
    }

    span {
      margin-left: 10px;
    }
  }

  .side-menu {
    height: calc(100% - 60px);
    overflow-y: auto;
    border: none;
  }

  .side-menu:not(.el-menu--collapse) {
    width: 220px;
  }
}
</style>
<style lang="scss">
@import "@assets/styles/variables.scss"; //公共样式变量


.el-menu-item-group,
.el-menu-item-group ul,
.el-menu-item-group ul .el-menu-item {}

.el-menu-item .is-active {
  background-color: #efefef !important;
}
</style>

<template>
  <section class="side-bar" style="background:rgb(254, 254, 254);">
    <h1 class="logo" @click="targetIndex">
      <img src="@assets/logo.png" alt="" style="width:32px;height:32px;" />
      <span v-show="!isCollapse" style="color: rgb(99, 99, 99);">{{ sysTitle }}</span>
    </h1>
    <el-menu class="side-menu" unique-opened router text-color="black" active-text-color="rgb(64, 158, 255)"
      :default-active="active" :collapse="isCollapse">
      <el-menu-item index="/homepage">
        <i class="el-icon-monitor"></i>
        <span slot="title">控制台</span>
      </el-menu-item>
      <SidebarGroup v-for="item in menulist" :key="item.amenu_id" :index="item.amenu_id + ''" :item="item" />
      <el-menu-item>
        <i class="el-icon-notebook-2"></i>
        <span slot="title" @click="jumpurl">操作手册</span>
      </el-menu-item>
      <!-- <el-submenu :index="item.amenu_id + ''" v-for="item in menulist" :key="item.amenu_id">
        <template slot="title">
          <i :class="item.amenu_icon"></i>
          <span slot="title">{{ item.amenu_name }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item :index="subItem.amenu_address" v-for="subItem in item.children" :key="subItem.id">
            <i :class="subItem.amenu_icon"></i>
            {{ subItem.amenu_name }}
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu> -->

    </el-menu>
  </section>
</template>

<script>
import { getUserMenu } from "@api/AdminMenu";
import { getGroupItemValue } from "@api/AdminUser";
import SidebarGroup from "./SidebarGroup";
import { TITLE } from "@config"; //引入系统Title
import { API_ROOT } from "@config";

export default {
  //组件
  components: {
    SidebarGroup
  },
  name: "Sidebar",
  props: ["isCollapse"],
  data() {
    return {
      active: "/homepage",
      menulist: [],
      sysTitle: "",
      platform_url: "", // 操作手册路由
    };
  },
  watch: {
    $route(val) {
      this.active = val.meta.parentPath ? val.meta.parentPath : val.fullPath;//使用完全体URL处理数据
      // this.active = val.path;
    },
  },
  created() {
    this.active = this.$route.meta.parentPath ? this.$route.meta.parentPath : this.$route.fullPath;
    // this.active = this.$route.path;
    // this.sysTitle = TITLE;
    this.CreatMenu();
  },
  methods: {
    jumpurl() {
      window.open(API_ROOT + this.platform_url);
    },
    targetIndex() {
      if (this.$route.path != "/homepage") {
        this.$router.replace("/homepage");
      }
    },
    async CreatMenu() {
      let { data: res } = await getUserMenu();
      this.menulist = res;
      sessionStorage.setItem("menuArr", JSON.stringify(this.menulist));
      let { data: restitle } = await getGroupItemValue("SYSTITLE");
      this.sysTitle = restitle.dgi_value ? restitle.dgi_value : TITLE;
      // 获取操作手册路由
      let { data: resplatform } = await getGroupItemValue("PLATFORM");
      this.platform_url = resplatform.dgi_value;
    },
  },
};
</script>
