/* 布局 */
//前台
import Layout from "@views/layout";

const plabannerRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
            {
            path: "plabanner",
            name: "PLABANNER",
            meta: {
                title: "广告图",
                sort: 1,
            },
            component: () => import("@views/business/plabanner/index.vue"),
            redirect: {
                name: "PLABANNER_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "PLABANNER_LIST",
                    meta: {
                        title: "广告图",
                        sort: 2,
                    },
                    component: () => import("@views/business/plabanner/list.vue"),
                },
                                {
                    path: "add/:refid",
                    name: "PLABANNER_ADD",
                    meta: {
                        title: "新增banner 广告图",
                        parentPath: '/business/plabanner/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plabanner/form.vue"),
                },
                                                {
                    path: "edit/:id",
                    name: "PLABANNER_EDIT",
                    meta: {
                        title: "编辑banner 广告图",
                        parentPath: '/business/plabanner/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plabanner/form.vue"),
                },
                                        ],
    },


        ],
};

export default plabannerRouter;
