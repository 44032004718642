<template>
    <div>
        <el-switch v-model="inputValue" />
    </div>

</template>

<script>
    export default {
        name: "TrySwitch",
        data() {
            return {
                inputValue: this.value == 1?true:false,
            };
        },
        props: {
            // 绑定的值
            value: {
                type: [Number,String],
                default: 0
            }
        },
        watch: {
            value:function(n){
                this.inputValue = n == 1 ? true:false;
            },
            inputValue: function (newValue) {
                // 修改调用者传入的值
                this.$emit('change', newValue)
            },
        }
    };
</script>

<style>

</style>
