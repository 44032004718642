import API from "@utils/request";
import { PAGE_SIZE } from "@config";

const ADRESS = "/user/";

/**
 * 登录
 * @param {object} params
 */
export async function login(params) {
  try {
    return await API.post("/login", params);
  } catch (error) {
    return error;
  }
}

/**
 * 获取验证码
 */
export async function captcha() {
  try {
    return await API.get("/captcha?r=" + new Date().getTime());
  } catch (error) {
    return error;
  }
}

/**
 * 修改密码
 * @param {object} params
 */
export async function uppassword(params) {
  try {
    return await API.post(ADRESS + "uppassword", params);
  } catch (error) {
    return error;
  }
}


/**
 * 获取验证码
 */
export async function clearCache() {
  try {
    return await API.get("/clearcache");
  } catch (error) {
    return error;
  }
}

/**
 * 获取管理员列表（分页）
 * @param {object} params
 * @param {object} pages
 */
export async function getUserList(params, pages) {
  try {

    return await API.get(ADRESS + "list", {
      params: {
        ...params,
        ...pages,
      },
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取管理员详情
 * @param {number} id
 */
export async function getUserInfo(id) {
  try {
    return await API.post(ADRESS + "info", {
      id: {
        id,
      },
    });
  } catch (error) {
    return error;
  }
}



/**
 * 编辑管理员
 * @param {object} params
 */
export async function editUser(params) {
  try {
    return await API.post(ADRESS + "edit", params);
  } catch (error) {
    return error;
  }
}


/**
 * 新增管理员
 * @param {object} params
 */
export async function addUser(params) {
  try {
    return await API.post(ADRESS + "add", params);
  } catch (error) {
    return error;
  }
}

/**
 * 刪除管理员
 * @param {number} id
 */
export async function deleteUser(id) {
  try {
    return await API.post(ADRESS + "delete", {
      id: {
        id,
      },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 修改管理员狀態
 * @param {number} id
 */
export async function disableUser(id, status) {
  try {
    return await API.post(ADRESS + "disable", {
      id: id,
      status: status,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 重置密码
 * @param {number} id
 */
export async function resetUserPwd(id) {
  try {
    return await API.post(ADRESS + "resetpassword", {
      id: id
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取角色键值对
 * @param {object} key
 * @param {object} value
 */
export async function getAdminUserKeyVal(key, value) {
  try {
    return await API.post(ADRESS + "getkeyval", {
      key: key,
      value: value,
    });
  } catch (error) {
    return error;
  }
}


/**
 * 获取系统参数配置
 * @param {object} params
 */
export async function getGroupItemValue(dgi_code) {
  try {
    return await API.post("/getgroupitemvalue", {
      dgi_code
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取系统内存使用情况
 * @param {object} params
 */
export async function getSysteamMemory(dgi_code) {
  try {
    return await API.post("/getsysteammemory");
  } catch (error) {
    return error;
  }
}



/**
 * 检测当前密码是否超过3个月
 */
export async function checkPwd() {
  try {
    return await API.post(ADRESS + "check_pwd");
  } catch (error) {
    return error;
  }
}
