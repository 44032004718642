/* 布局 */
//前台
import Layout from "@views/layout";

const questionsourceRouter = {
path: "/business",
component: Layout,
redirect: {
name: "BUSINESS"
},
children: [
                {
            path: "questionsource/list",
            name: "QUESTIONSOURCE",
            meta: {
            title: "题库数据源",
            },
            component: () => import("@views/business/questionsource/index.vue"),
            },
            ],
};

export default questionsourceRouter;
