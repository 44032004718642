/* 布局 */
//前台
import Layout from "@views/layout";

const paperquestionRouter = {
path: "/business",
component: Layout,
redirect: {
name: "BUSINESS"
},
children: [
                {
            path: "paperquestion/list",
            name: "PAPERQUESTION",
            meta: {
            title: "试卷试题",
            },
            component: () => import("@views/business/paperquestion/index.vue"),
            },
            ],
};

export default paperquestionRouter;
