/* 布局 */
//前台
import Layout from "@views/layout";

const paperRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "paper/list",
            name: "PAPER",
            meta: {
                title: "试卷管理",
            },
            component: () => import("@views/business/paper/list.vue"),
        },
        {
            path: "add/:refid",
            name: "PAPER_ADD",
            meta: {
                title: "新增试卷",
                parentPath: '/business/paper/list',
                operate: true,
            },
            component: () => import("@views/business/paper/form.vue"),
        },
        {
            path: "edit/:id",
            name: "PAPER_EDIT",
            meta: {
                title: "编辑试卷",
                parentPath: '/business/paper/list',
                operate: true,
            },
            component: () => import("@views/business/paper/form.vue"),
        },
        {
            path: "questionlist/:pid",
            name: "PAPERQUESTION_LIST",
            meta: {
                title: "试题配置",
                parentPath: '/business/paper/list',
                operate: true,
            },
            component: () => import("@views/business/paperquestion/questionlist.vue"),
        },
    ],
};

export default paperRouter;
