/* 布局 */
//前台
import Layout from "@views/layout";

const schclassRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "schclass/list/:scsch_id/:scsch_name",
        name: "SCHCLASS",
        meta: {
            title: "学校班级表",
        },
        component: () => import("@views/business/schclass/index.vue"),
    },  {
        path: "schclass/class/:scsch_id/:scsch_name",
        name: "SCHCLASS",
        meta: {
            title: "学校班级表",
        },
        component: () => import("@views/business/schclass/class.vue"),
    },
        ],
};

export default schclassRouter;
