/* 布局 */
//前台
import Layout from "@views/layout";

const schcoursesRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "schcourses/list",
        name: "SCHCOURSES",
        meta: {
            title: "学校教材表",
        },
        component: () => import("@views/business/schcourses/index.vue"),
    }, {
        path: "schcourses/examine_list",
        name: "SCHCOURSES",
        meta: {
            title: "学校教材表",
        },
        component: () => import("@views/business/schcourses/examine_list.vue"),
    },
        ],
};

export default schcoursesRouter;
