/* 布局 */
//前台
import Layout from "@views/layout";

const placoursewareRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "placourseware/list",
        name: "PLACOURSEWARE",
        meta: {
            title: "公共课件库",
        },
        component: () => import("@views/business/placourseware/index.vue"),
    },
        ],
};

export default placoursewareRouter;
