/* 布局 */
//前台
import Layout from "@views/layout";

const plapaperRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "plapaper",
            name: "PLAPAPER",
            meta: {
                title: "公共试卷库",
                sort: 1,
            },
            component: () => import("@views/business/plapaper/index.vue"),
            redirect: {
                name: "PLAPAPER_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "PLAPAPER_LIST",
                    meta: {
                        title: "公共试卷库",
                        sort: 2,
                    },
                    component: () => import("@views/business/plapaper/list.vue"),
                },
                {
                    path: "add/:refid",
                    name: "PLAPAPER_ADD",
                    meta: {
                        title: "新增平台试卷库",
                        parentPath: '/business/plapaper/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plapaper/form.vue"),
                },
                {
                    path: "edit/:id",
                    name: "PLAPAPER_EDIT",
                    meta: {
                        title: "编辑平台试卷库",
                        parentPath: '/business/plapaper/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plapaper/form.vue"),
                },
                {
                    path: "examinelist",
                    name: "PLAPAPER_EXAMINELIST",
                    meta: {
                        title: "试卷共享审核",
                    },
                    component: () => import("@views/business/plapaper/examinelist.vue"),
                },
                {
                    path: "examinedetails/:audit/:id",
                    name: "PLAPAPER_EXAMINEDETAILS",
                    meta: {
                        title: "试卷审核详情",
                        parentPath: '/business/plapaper/examinelist',
                        operate: true,
                    },
                    component: () => import("@views/business/plapaper/examinedetails.vue"),
                },
                
            ],
        }

    ],
};

export default plapaperRouter;
