/* 布局 */
//前台
import Layout from "@views/layout";

const schschoolRouter = {
	path: "/business",
	component: Layout,
	redirect: {
		name: "BUSINESS"
	},
	children: [{
			path: "schschool",
			name: "SCHSCHOOL",
			meta: {
				title: "学校表",
				sort: 1,
			},
			component: () => import("@views/business/schschool/index.vue"),
			redirect: {
				name: "SCHSCHOOL_LIST",
			},
			children: [{
					path: "list",
					name: "SCHSCHOOL_LIST",
					meta: {
						title: "学校表",
						sort: 2,
					},
					component: () => import("@views/business/schschool/list.vue"),
				},{
					path: "examine_list",
					name: "SCHSCHOOLEXAMINE_LIST",
					meta: {
						title: "审核列表",
						sort: 2,
					},
					component: () => import("@views/business/schschool/examine_list.vue"),
				},
				{
					path: "add/:refid",
					name: "SCHSCHOOL_ADD",
					meta: {
						title: "新增学校表",
						parentPath: '/business/schschool/list',
						operate: true,
					},
					component: () => import("@views/business/schschool/form.vue"),
				},
				{
					path: "edit/:id",
					name: "SCHSCHOOL_EDIT",
					meta: {
						title: "编辑学校表",
						parentPath: '/business/schschool/list',
						operate: true,
					},
					component: () => import("@views/business/schschool/form.vue"),
				},{
					path: "examine_edit/:id",
					name: "EXAMINE_EDIT",
					meta: {
						title: "审核学校表",
						parentPath: '/business/schschool/list',
						operate: true,
					},
					component: () => import("@views/business/schschool/examine_form.vue"),
				}
			],
		},


	],
};

export default schschoolRouter;
