/* 布局 */
//前台
import Layout from "@views/layout";

const memberlevelRouter = {
path: "/business",
component: Layout,
redirect: {
name: "BUSINESS"
},
children: [
                {
            path: "memberlevel/list",
            name: "MEMBERLEVEL",
            meta: {
            title: "会员类型",
            },
            component: () => import("@views/business/memberlevel/index.vue"),
            },
            ],
};

export default memberlevelRouter;
