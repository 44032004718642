/* 布局 */
//前台
import Layout from "@views/layout";

const plaboardversionRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "plaboardversion/list",
        name: "PLABOARDVERSION",
        meta: {
            title: "服务端版本",
        },
        component: () => import("@views/business/plaboardversion/index.vue"),
    },
        ],
};

export default plaboardversionRouter;
