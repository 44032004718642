/* 布局 */
//前台
import Layout from "@views/layout";

const plaremarkRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "plaremark/list",
        name: "PLAREMARK",
        meta: {
            title: "平台评语管理",
        },
        component: () => import("@views/business/plaremark/index.vue"),
    },
        ],
};

export default plaremarkRouter;
