/* 布局 */
//前台
import Layout from "@views/layout";

const schquestionscschidRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "schquestionscschid/list",
        name: "SCHQUESTIONSCSCHID",
        meta: {
            title: "校本题目",
        },
        component: () => import("@views/business/schquestionscschid/index.vue"),
    },
        ],
};

export default schquestionscschidRouter;
