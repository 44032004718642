/* 布局 */
//前台
import Layout from "@views/layout";

const plawarehouselogRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "plawarehouselog/list",
            name: "PLAWAREHOUSELOG",
            meta: {
                title: "库存动态",
            },
            component: () => import("@views/business/plawarehouselog/index.vue"),
        },
        {
            path: "plawarehouselog/outstock",
            name: "PLAWAREHOUSELOG",
            meta: {
                title: "出库管理",
            },
            component: () => import("@views/business/plawarehouselog/outstock.vue"),
        },
    ],
};

export default plawarehouselogRouter;
