<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量

.header {
  width: 100%;
  height: 60px;
  border-bottom: $borderSlide;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px;

  .tool-left,
  .tool-right {
    height: 100%;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;

    .tool-item {
      color: $color-text-regular;
      cursor: pointer;
      padding: 0 12px;
      height: 60px;
      display: flex;
      align-items: center;

      &:hover {
        background: $tool-hover-bg;
      }

      .user-name {
        font-size: 14px;
        color: $color-text-regular;
        margin-left: 4px;

        span {
          margin-left: 4px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@assets/styles/variables.scss"; //公共样式变量

.el-badge__content {
  padding: 0 5px;
}

.el-badge__content.is-fixed {
  top: 20px;
  right: 24px;
}

.el-dropdown-menu__item span {
  margin-right: 5px;
}

.el-breadcrumb__inner,
.el-breadcrumb__inner.is-link,
.el-breadcrumb__inner a {
  color: $color-text-secondary;
}
</style>

<template>
  <section class="header">
    <!-- 左侧工具栏 -->
    <div class="tool-left">
      <!-- 导航栏收起/展开 -->
      <div class="tool-item" @click="handleChangeCollapse">
        <div v-if="isCollapse" class="iconfont">&#xe8fc;</div>
        <div v-if="!isCollapse" class="iconfont">&#xe600;</div>
      </div>
      <!-- 页面刷新 -->
      <div class="tool-item refresh el-icon-refresh-right" @click="handleRefresh" />
      <!-- 面包屑 -->
      <el-breadcrumb separator="/">
        <template v-for="(item, index) in breadcrumbData">
          <el-breadcrumb-item :key="index" v-if="item.meta.title">
            {{ item.meta.title }}
          </el-breadcrumb-item>
        </template>
      </el-breadcrumb>
    </div>
    <!-- 右侧工具栏 -->
    <div class="tool-right">
      <!-- 全屏展开/收起 -->
      <div class="tool-item" @click="handleFullScreen">
        <div class="iconfont">{{ (isFull && "&#xe641;") || "&#xe8fa;" }}</div>
      </div>
      <!-- <el-popover placement="bottom" width="330" trigger="click">
        <el-badge slot="reference" :value="10" class="tool-item">
          <span class="el-icon-bell" />
        </el-badge>
        <el-tabs v-model="activeName" @tab-click="handleTabClick" stretch>
          <el-tab-pane label="通知" name="1">数据内容</el-tab-pane>
          <el-tab-pane label="私信" name="2">数据内容</el-tab-pane>
          <el-tab-pane label="待办" name="3">数据内容</el-tab-pane>
        </el-tabs>
      </el-popover> -->
      <el-dropdown @command="handleDropdownItem">
        <div class="tool-item">
          <el-avatar :size="28" :src="avatarurl"> </el-avatar>
          <p class="user-name">
            {{ username }}<span class="el-icon-arrow-down" />
          </p>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="modifyPwd">
            <span class="el-icon-key" />修改密码
          </el-dropdown-item>
          <el-dropdown-item divided command="loginOut">
            <span class="el-icon-switch-button" />退出登录
          </el-dropdown-item>
          <el-dropdown-item divided command="clearCache">
            <span class="el-icon-delete" />清除缓存
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 修改密码弹窗 -->
    <el-dialog title="修改密码" :visible.sync="pwdDialogVisible" width="420px" :close-on-click-modal="false"
      :close-on-press-escape="false" :show-close="isUpdatePwd == 2">
      <el-form :model="pwdForm" :rules="rules" ref="pwdForm" label-width="84px">
        <el-form-item label="原密码:" prop="oldPwd">
          <el-input type="password" v-model="pwdForm.oldPwd" placeholder="请输入原密码" />
        </el-form-item>
        <el-form-item label="新密码:" prop="newPwd">
          <el-input type="password" v-model="pwdForm.newPwd" placeholder="请输入新密码" />
        </el-form-item>
        <el-form-item label="确认密码:" prop="newPwdTwo">
          <el-input type="password" v-model="pwdForm.newPwdTwo" placeholder="请再次输入密码" />
        </el-form-item>
        <el-tag type="danger" v-if="isUpdatePwd == 1">提示：当前密码已超过{{ exceedDay }}天未修改，请修改密码</el-tag>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancle" v-if="isUpdatePwd == 2">取 消</el-button>
        <el-button type="primary" @click="handleSubmit"> 确 定 </el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { validPassword } from "@utils/validate"; //引入密码校验规则
import { mapActions } from "vuex";
import { uppassword,clearCache, checkPwd } from "@api/AdminUser";
export default {
  name: "Navbar",
  inject: ["reload"],
  props: ["isCollapse"],
  data() {
    // 初次输入新密码校验规则
    let newPwdValidator = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入新密码！"));
      } else {
        if (value.length < 8 || value.length > 20) {
          callback(new Error("密码长度在 8 到 20 个字符"));
        } else if (!validPassword(value)) {
          callback(new Error("必须包含数字、大小写字母、特殊符号"));
        }
        callback();
      }
    };
    // 确认密码校验规则
    let newPwd2Validator = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请确认密码！"));
      } else {
        if (value.length < 8 || value.length > 20) {
          callback(new Error("密码长度在 8 到 20 个字符"));
        } else if (value != this.pwdForm.newPwd) {
          callback(new Error("两次输入密码不相同！"));
        }
        callback();
      }
    };
    return {
      isFull: false, //全屏显示
      activeName: "1", //消息通知高亮栏目
      breadcrumbData: [], // 面包屑数据
      pwdDialogVisible: false, //修改密码弹窗显示
      pwdForm: {}, //表单存储数据
      username: "",
      avatarurl: "",
      rules: {
        oldPwd: [
          { required: true, message: "请输入原密码！", trigger: "blur" },
        ],
        newPwd: [
          { required: true, validator: newPwdValidator, trigger: "blur" },
        ],
        newPwdTwo: [
          { required: true, validator: newPwd2Validator, trigger: "blur" },
        ],
      }, //表单校验规则

      isUpdatePwd: 2, // 是否需要强制修改密码：1=是，2=否
      exceedDay: 0, // 当前过期天数
    };
  },
  watch: {
    $route(val) {
      this.breadcrumbData = val.matched;
    },
  },
  created() {
    this.breadcrumbData = this.$route.matched;
    this.getBranchList();
    this.getMenuList();
    this.username = sessionStorage.getItem("username");
    this.avatarurl = sessionStorage.getItem("avatar");
    this.checkPwd();
  },
  mounted() {
    window.addEventListener("resize", () => {
      // 窗口尺寸改变时触发事件
      if (!this.$screenfull.isFullscreen) {
        this.isFull = false;
      }
    });
  },
  methods: {
    /** 检测是否需要修改密码 */
    async checkPwd() {
      const { data: rescheck } = await checkPwd();
      console.log(rescheck);
      this.isUpdatePwd = rescheck.isupdatepwd;
      if (this.isUpdatePwd == 1) {
        this.exceedDay = rescheck.checkday;
        this.pwdDialogVisible = true;
      }
    },

    ...mapActions("system", ["getBranchList", "getMenuList"]),
    /** 收起/展开导航栏 */
    handleChangeCollapse() {
      this.$emit("handleChangeCollapse");
    },
    /** 刷新页面 */
    handleRefresh() {
      this.reload();
    },
    /** 点击全屏 */
    handleFullScreen() {
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.$screenfull.request();
      } else {
        this.$screenfull.exit();
      }
    },
    /** 消息类型切换 */
    handleTabClick() {
      console.log("消息通知栏目切换");
    },
    /** 下拉列表项点击 */
    handleDropdownItem(command) {
      switch (command) {
        case "modifyPwd":
          // 修改密码
          this.pwdForm = {};
          this.pwdDialogVisible = true;
          break;
        case "loginOut":
          // 退出登录
          sessionStorage.clear();
          this.$router.replace("/login");
          break;
        case "clearCache":
          // 清除缓存
          clearCache().then((res) => {
            if(res.status == 200){
              this.$message({ message: "清除成功", type: "success" });return false;
            }else{
              this.$message({ message: "清除失败", type: "error" });return false;
            }
          });
          
          break;
        default:
          break;
      }
    },
    /** 取消修改密码 */
    handleCancle() {
      this.pwdDialogVisible = false;
      this.pwdForm = {};
      this.$refs["formData"].clearValidate();
    },
    /** 提交密码修改 */
    handleSubmit() {
      /**
       * 请求接口，修改密码
       */
      this.$refs["pwdForm"].validate((valid) => {
        if (valid) {
          uppassword(this.pwdForm).then((res) => {
            if (res.status != 200) return;
            this.$message({ message: res.msg, type: "success" });
            this.pwdDialogVisible = false;
            // 退出登录
            sessionStorage.clear();
            this.$router.replace("/login");
          });
        }
      });
    },
  },
};
</script>