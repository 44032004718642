import API from "@utils/request";
import { PAGE_SIZE } from "@config";

const ADRESS = "/department/";


/**
 * 获取部门列表
 * @param {object} params
 * @param {object} pages
 */
export async function getDepartmentList(params) {
  try {

    return await API.get(ADRESS + "list", {
      params: {
        ...params
      },
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取未隐藏的部门列表
 * @param {object} params
 * @param {object} pages
 */
export async function getDepartmentShowList(params) {
  try {

    return await API.get(ADRESS + "showlist", {
      params: {
        ...params
      },
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取部门详情
 * @param {number} id
 */
export async function getDepartmentInfo(id) {
  try {
    return await API.post(ADRESS + "info", {
      id: {
        id,
      },
    });
  } catch (error) {
    return error;
  }
}



/**
 * 编辑部门
 * @param {object} params
 */
export async function editDepartment(params) {
  try {
    return await API.post(ADRESS + "edit", params);
  } catch (error) {
    return error;
  }
}


/**
 * 新增部门
 * @param {object} params
 */
export async function addDepartment(params) {
  try {
    return await API.post(ADRESS + "add", params);
  } catch (error) {
    return error;
  }
}

/**
 * 刪除部门
 * @param {number} id
 */
export async function deleteDepartment(id) {
  try {
    return await API.post(ADRESS + "delete", {
      id: {
        id,
      },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 修改部门狀態
 * @param {number} id
 */
export async function disableDepartment(id, status) {
  try {
    return await API.post(ADRESS + "disable", {
      id: id,
      status: status,
    });
  } catch (error) {
    return error;
  }
}

/**
* 获取用户键值对
* @author 四川创企
* @DateTime 2022/05/28
* @param {object} key
* @param {object} value
*/
export async function getAdminDepartmentKeyVal(key, value) {
  try {
    return await API.post(ADRESS + "getkeyval", {
      key: key,
      value: value,
    });
  } catch (error) {
    return error;
  }
}

/**
* 获取岗位数据权限ID清单
*/
export async function getJobDepId(jobid) {
  try {
    return await API.post(ADRESS + "getjobdepid", { jobid: jobid }, {
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取树形数据权限列表
*/
export async function getTreeDep() {
  try {
    return await API.post(ADRESS + "gettreedep", {
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 保存数据权限
*/
export async function saveJobDep(jobid, deparr) {
  try {
    return await API.post(ADRESS + "savejobdep",
      { jobid: jobid, deparr: deparr },
      {
        headers: { isLoading: true },
      });
  } catch (error) {
    return error;
  }
}

/**
 * 获取用户数据权限
*/
export async function getDepTreeByUserId() {
  try {
    return await API.post(ADRESS + "getdeptreebyuserid",
      {},
      {
        headers: { isLoading: true },
      });
  } catch (error) {
    return error;
  }
}

/**
 * 获取树形部门数据[key,value]
*/
export async function getTreeDepKeyVal() {
  try {
    return await API.post(ADRESS + "gettreedepkeyval",
      {},
      {
        headers: { isLoading: true },
      });
  } catch (error) {
    return error;
  }
}