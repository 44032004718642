/* 布局 */
//前台
import Layout from "@views/layout";

const plapurchaseRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "plapurchase",
            name: "PLAPURCHASE",
            meta: {
                title: "采购管理",
                sort: 1,
            },
            component: () => import("@views/business/plapurchase/index.vue"),
            redirect: {
                name: "PLAPURCHASE_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "PLAPURCHASE_LIST",
                    meta: {
                        title: "采购管理",
                        sort: 2,
                    },
                    component: () => import("@views/business/plapurchase/list.vue"),
                },
                {
                    path: "add/:refid",
                    name: "PLAPURCHASE_ADD",
                    meta: {
                        title: "新增采购管理",
                        parentPath: '/business/plapurchase/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plapurchase/form.vue"),
                },
                {
                    path: "edit/:id/:ismylist/:isexamine",
                    name: "PLAPURCHASE_EDIT",
                    meta: {
                        title: "编辑采购管理",
                        parentPath: '/business/plapurchase/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plapurchase/form.vue"),
                },
            ],
        },
        {
            path: "plapurchase/mylist",
            name: "PLAPURCHASE",
            meta: {
                title: "采购审核",
            },
            component: () => import("@views/business/plapurchase/mylist.vue"),
        },

    ],
};

export default plapurchaseRouter;
