/* 布局 */
//前台
import Layout from "@views/layout";

const stuquestionscschidRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "stuquestionscschid",
            name: "STUQUESTIONSCSCHID",
            meta: {
                title: "学生错题库",
                sort: 1,
            },
            component: () => import("@views/business/stuquestionscschid/index.vue"),
            redirect: {
                name: "STUQUESTIONSCSCHID_LIST",
            },
            children: [
                {
                    path: "list/:subject",
                    name: "STUQUESTIONSCSCHID_LIST",
                    meta: {
                        title: "学生错题库",
                        sort: 2,
                    },
                    component: () => import("@views/business/stuquestionscschid/list.vue"),
                },
                {
                    path: "add/:refid",
                    name: "STUQUESTIONSCSCHID_ADD",
                    meta: {
                        title: "新增学生错题库",
                        parentPath: '/business/stuquestionscschid/list',
                        operate: true,
                    },
                    component: () => import("@views/business/stuquestionscschid/form.vue"),
                },
                {
                    path: "edit/:id",
                    name: "STUQUESTIONSCSCHID_EDIT",
                    meta: {
                        title: "编辑学生错题库",
                        parentPath: '/business/stuquestionscschid/list',
                        operate: true,
                    },
                    component: () => import("@views/business/stuquestionscschid/form.vue"),
                },
            ],
        },
        {
            path: "stuquestionscschid/home",
            name: "STUQUESTIONSCSCHID_HOME",
            meta: {
                title: "学生错题库",
            },
            component: () => import("@views/business/stuquestionscschid/home.vue"),
        },

    ],
};

export default stuquestionscschidRouter;
