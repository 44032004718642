/* 布局 */
//前台
import Layout from "@views/layout";

const plawarehouseRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "plawarehouse/list",
            name: "PLAWAREHOUSE",
            meta: {
                title: "仓库管理",
            },
            component: () => import("@views/business/plawarehouse/index.vue"),
        },
    ],
};

export default plawarehouseRouter;
