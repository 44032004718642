/* 布局 */
//前台
import Layout from "@views/layout";

const plachargeclassRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "plachargeclass/list",
        name: "PLACHARGECLASS",
        meta: {
            title: "收费类别表",
        },
        component: () => import("@views/business/plachargeclass/index.vue"),
    },
        ],
};

export default plachargeclassRouter;
