<template>
    <!-- 富文本下拉框 -->
    <div class="rich_select">
        <div class="operating">
          <div class="input" @click="showlist=true">
            <el-input :placeholder="Object.entries(atPresentQues).length?'':placeholder" v-model="inputval" clearable @focus="showinput=true" @input="inputfn" @blur="hidelist"> </el-input>
          </div>
          <div class="optname" :class="showinput?'hideopt':''">
            <div class="rich">
              <em class="val" v-html="atPresentQues[title]"></em>
              <img v-if="atPresentQues[title+'_image']" :src="formatfile(atPresentQues[title+'_image'])">
            </div>
          </div>
        </div>
        <template v-if="showlist">
          <div class="list" v-if="list.length" ref="scrollContainer" @scroll="handleScroll">
            <div class="richitem" v-for="(item,index) in list" :key="item.plque_id" @click="clickitem(item)" :class="atPresentQues[valkey]==item[valkey]?'opt':''">
              <div class="index">{{ index+1 }}</div>
              <div class="rich">
                <em class="val"  v-html="item[title]"></em>
                <img v-if="item[title+'_image']" :src="formatfile(item[title+'_image'])">
              </div>
            </div>
            <div class="more" v-if="more">加载中</div>
          </div>
          <div class="nomore" v-else>
              无数据
          </div>
        </template>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import { formatFile} from "@utils";
let _this;
export default {
  name: "richSelect",
  props: {
    //显示的字段
    title: {
      type: String,
      default: "plque_title",
    },
    valkey:{
      type: String,
      default: "plque_id",
    },
    //选中的值
    value:{
      type: String | Number,
    },
    //内容
    list: {
      type: Array,
    },
    //是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    //是否有更多
    more: {
      type: Boolean,
      default: false,
    },
    //提示文字
    placeholder: {
      type: String,
      default: "请输入关键字",
    },

  },
  computed:{
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
  },

  watch: {
    //监听内容变化
    value(newValue) {
      if(newValue){
        this.optval = newValue;
      }else{
        this.atPresentQues={}
      }
    },
  },
  data() {
    return {
      //选中的值
      optval:0,
      //输入框
      inputval:"",
      //选中的值
      atPresentQues:{},
      //显示列表
      showlist:false,
      // 只显示input框
      showinput:false,
    };
  },
  mounted: function () {
    _this=this
  },
  created() { 

  },
  methods: {
    // 滚动加载更多
    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container) {
        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;
        // 如果滚动到底部
        if (scrollTop + clientHeight >= scrollHeight) {
          this.$emit("reachBottom");
          // 这里可以触发你想要执行的操作
        }
      }
    },
    //设置input框内容
    inputfn(e){
      this.atPresentQues={}
      this.filterQues(e)
    },
    // 输入内容切换 使用防抖函数包装处理方法
    filterQues: debounce(e=> {
      _this.$emit("remoteMethod", e);
    }, 500), 
    // 点击单题
    clickitem(item) {
      this.inputval=""
      this.atPresentQues=item
      this.showlist=false
      this.$emit("input", item[this.valkey]);
      this.$emit("queSelect", {id:item[this.valkey],item:item});
    },
    //隐藏选择框
    hidelist(){
      this.showinput=false
      setTimeout(()=>{
        this.showlist=false
      },300)
    }
  },
};
</script>
<style lang="scss" scoped>
.rich_select{
  position: relative;
  height: 46px;
  z-index: 50;

  .operating{
    position: relative;
    width: 100%;
    height: 46px;
    background-color: #F6F7FA;
    border-radius: 10px;
    .input{
      position: relative;
      z-index: 12;
      ::v-deep .el-input__inner{
        background-color: rgba(0,0,0,0);
      }
    }
    .optname{
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      padding: 0 28px;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      height: 46px;
      &.hideopt{
        opacity: 0.6;
      }
    }
  } 
  
::v-deep {
  .rich{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 40px;
    line-height: 24px;
    *{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      
    }
    img{
      width: 30px !important;
      height: 30px !important;
    }
  }
}
  .nomore{
    position: absolute;
    bottom: 46px;
    left: 0;
    z-index: 15;
    border-radius: 10px;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 76px;
    color: #999;
    font-size: 14px;
    width: 220px;
    background-color: #F6F7FA;
  }
  .list{
    width: 100%;
    max-height: 300px;
    overflow: auto;
    border:1px solid #DDDDDD;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    position: absolute;
    bottom: 46px;
    left: 0;
    z-index: 15;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.2); 
    .more{
      text-align: center;
      color: #cccccc;
    }
    .richitem{
      position: relative;
      margin-bottom: 5px;
      padding: 10px 10px 10px 50px;
      min-height: 40px;
      background-color: #F9F9F9;
      border-radius: 10px;
      &:hover{
        background-color: #EEEEEE;
        cursor: pointer;
      }
      &.opt{
        background-color: #6340C8;
        *{
          color: #FFFFFF !important;
        }
      }
      .index{
        position:absolute;
        left: 20px;
        line-height: 46px;
        width: 20px;
        text-align: right;

      }
    }
  }
}
</style>
