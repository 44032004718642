/* 布局 */
//前台
import Layout from "@views/layout";

const plaorderRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "plaorder",
            name: "PLAORDER",
            meta: {
                title: "订单管理",
                sort: 1,
            },
            component: () => import("@views/business/plaorder/index.vue"),
            redirect: {
                name: "PLAORDER_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "PLAORDER_LIST",
                    meta: {
                        title: "订单管理",
                        sort: 2,
                    },
                    component: () => import("@views/business/plaorder/list.vue"),
                },
                {
                    path: "add/:refid",
                    name: "PLAORDER_ADD",
                    meta: {
                        title: "新增订单管理",
                        parentPath: '/business/plaorder/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plaorder/form.vue"),
                },
                {
                    path: "edit/:id/:isrefund",
                    name: "PLAORDER_EDIT",
                    meta: {
                        title: "编辑订单管理",
                        parentPath: '/business/plaorder/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plaorder/form.vue"),
                },
            ],
        },
        {
            path: "plaorder/checklist",
            name: "PLAORDER_CHECKLIST",
            meta: {
                title: "财务对账单",
            },
            component: () => import("@views/business/plaorder/checklist.vue"),
        },

    ],
};

export default plaorderRouter;
