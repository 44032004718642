/* 布局 */
//前台
import Layout from "@views/layout";

const teauserRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "teauser",
            name: "TEAUSER",
            meta: {
                title: "教师表",
                sort: 1,
            },
            component: () => import("@views/business/teauser/index.vue"),
            redirect: {
                name: "TEAUSER_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "TEAUSER_LIST",
                    meta: {
                        title: "教师表",
                        sort: 2,
                    },
                    component: () => import("@views/business/teauser/list.vue"),
                },
                {
                    path: "add/:refid",
                    name: "TEAUSER_ADD",
                    meta: {
                        title: "新增教师表",
                        parentPath: '/business/teauser/list',
                        operate: true,
                    },
                    component: () => import("@views/business/teauser/form.vue"),
                },
                {
                    path: "edit/:id",
                    name: "TEAUSER_EDIT",
                    meta: {
                        title: "编辑教师表",
                        parentPath: '/business/teauser/list',
                        operate: true,
                    },
                    component: () => import("@views/business/teauser/form.vue"),
                },
                {
                    path: "details/:id",
                    name: "TEAUSER_DETAILS",
                    meta: {
                        title: "教师详情",
                        parentPath: '/business/teauser/list',
                        operate: true,
                    },
                    component: () => import("@views/business/teauser/details.vue"),
                },
            ],
        },


    ],
};

export default teauserRouter;
