
<template>
  <!-- 有子集 -->
  <div v-if="item.children.length>0">
    <el-submenu :index="item.amenu_id + ''">
      <template slot="title">
        <i :class="item.amenu_icon"></i>
        <span slot="title">{{ item.amenu_name }}</span>
      </template>
      <el-menu-item-group>
        <SidebarGroup v-for="subItem in item.children" :key="subItem.id" :item="subItem"/>
      </el-menu-item-group>
    </el-submenu>
  </div>
  <!-- 无子集 -->
  <el-menu-item :index="item.amenu_address + ''" v-else>
    <i :class="item.amenu_icon"></i>
    {{ item.amenu_name }}
  </el-menu-item>
</template>

<script>
export default {
  name: "SidebarGroup",
  props: ["item"],
  data() {
    return {
    };
  },
  methods: {
    //点击跳转
    clickMenu(item) {
      console.log(item)
      this.$router.push({
        name: item.name,
      });
      //设置tags导航
      this.$store.commit("selectMent",item)
    },
  }
};
</script>
