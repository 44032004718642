/* 布局 */
//前台
import Layout from "@views/layout";

const plafeedbackRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "plafeedback/list",
        name: "PLAFEEDBACK",
        meta: {
            title: "意见反馈",
        },
        component: () => import("@views/business/plafeedback/index.vue"),
    },
        ],
};

export default plafeedbackRouter;
