/* 布局 */
//前台
import Layout from "@views/layout";

const plaorderrefundreasonRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "plaorderrefundreason/list",
        name: "PLAORDERREFUNDREASON",
        meta: {
            title: "平台订单退款理由",
        },
        component: () => import("@views/business/plaorderrefundreason/index.vue"),
    },
        ],
};

export default plaorderrefundreasonRouter;
