/* 布局 */
//前台
import Layout from "@views/layout";

const plamessageRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
            {
            path: "plamessage",
            name: "PLAMESSAGE",
            meta: {
                title: "消息管理",
                sort: 1,
            },
            component: () => import("@views/business/plamessage/index.vue"),
            redirect: {
                name: "PLAMESSAGE_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "PLAMESSAGE_LIST",
                    meta: {
                        title: "消息管理",
                        sort: 2,
                    },
                    component: () => import("@views/business/plamessage/list.vue"),
                },
                                {
                    path: "add/:refid",
                    name: "PLAMESSAGE_ADD",
                    meta: {
                        title: "新增平台消息通知表",
                        parentPath: '/business/plamessage/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plamessage/form.vue"),
                },
                                                {
                    path: "edit/:id",
                    name: "PLAMESSAGE_EDIT",
                    meta: {
                        title: "编辑平台消息通知表",
                        parentPath: '/business/plamessage/list',
                        operate: true,
                    },
                    component: () => import("@views/business/plamessage/form.vue"),
                },
                                        ],
    },


        ],
};

export default plamessageRouter;
