import API from "@utils/request";

const COMMON = "/common/";
import HTTP from "@utils/http";

/**
 * 文件上传
 * @param {file} file
 */
export async function uploadFile(file) {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);

    return await API.post(COMMON + "/uploadfile", formData, {
      transformRequest: [
        function (data) {
          return data;
        },
      ],
    });
  } catch (error) {
    return error;
  }
}


/*******
 * @Descripttion: 获取科目数据
 * @Author: Dyf
 * @return {*}
 */
export async function $getSubject() {
  try {
    return await API.get("/tool/subject");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 文件下载
 * @Author: Dyf
 * @param {string} filePath 资源文件地址
 * @return {*}
 */
export async function $downloadFile(filePath) {
  try {
    return await HTTP.post("/protal/common/download_file", { filePath },{noControl: true});
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取年级数据
 * @Author: Wyp
 * @return {*}
 */
export async function gradeData() {
  try {
    return await API.post("/tool/grade_data");
  } catch (error) {
    return error;
  }
}

/**
 * 获取学校班级表列表（树状）
 * @author 四川创企
 * @DateTime 2023/04/24
 * @param {object} params
 * @param {object} pages
 */
export async function getGradeClass(params) {
	try {
		return await API.get( "/schclass/tree_list", {
			params: {
				...params,
			},
			
		});
	} catch (error) {
		return error;
	}
}



/*******
 * @Descripttion: 获取省市区数据
 * @Author: Dyf
 * @return {*}
 */
export async function $getProvinceData() {
  try {
    return await HTTP.post("/protal/common/get_province_city_area");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取题型数据
 * @Author: Dyf
 * @return {*}
 */
export async function $getQuestionType() {
  try {
    return await HTTP.get("/protal/tool/question_type",{},{noControl:true});
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 上传图片
 * @Author: Dyf
 * @param {file} file 图片文件
 * @return {*}
 */
export async function $uploadImg(file) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  try {
    return await HTTP.post("/protal/tool/upLoadimage", formData);
  } catch (error) {
    return error;
  }
}
