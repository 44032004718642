<template>
  <div v-loading="editorLoading">
    <Editor :id="tinymceId" :init="init" :disabled="disabled" :width="width" :height="height" v-model="myValue">
    </Editor>
  </div>
</template>

<script>
//引入tinymce-vue
import Editor from '@tinymce/tinymce-vue'
import { API_ROOT } from "@config";
export default {
  name: "TryEditor",
  components: {
    Editor
  },
  props: {
    //编号
    id: {
      type: String
    },
    //内容
    value: {
      type: String,
      default: ''
    },
    //是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否开启全功能
    allFunctions: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    // 高度 默认300
    height: {
      type: Number,
      default: () => {
        return 600;
      },
    },

    // 宽度 默认900
    width: {
      type: Number,
      default: () => {
        return 900;
      },
    },

    keep_styles: true
  },
  mounted: function () {
  },
  data() {
    let that = this;
    return {
      tinymceId: this.id || 'vue-tinymce' + Date.parse(new Date()) + parseInt(Math.random() * 10000),
      myValue: this.value,
      editorLoading: false,//loading层
      init: {
        placeholder: this.placeholder,
        valid_elements: "*[*]",
        selector: this.tinymceId,
        //汉化,路径是自定义的，一般放在public或static里面，汉化文件要自己去下载
        language: 'zh_CN',
        //皮肤
        skin: 'oxide',
        convert_urls: false,
        automatic_uploads: true,
        images_upload_url: API_ROOT + "/protal/tool/upLoadimage?accesstoken=" + sessionStorage.getItem("accessToken"),
        // images_upload_url: $upload.imgAction+"?accesstoken=" + sessionStorage.getItem("accessToken"),
        //隐藏技术支持
        branding: false,
        //隐藏底栏的元素路径
        elementpath: false,
        //关闭菜单
        menubar: false,// 菜单栏
        //隐藏状态栏
        // statusbar: false,
        //高度
        height: this.height,
        default_link_target: "_blank",

        //   inline:true,

        buttonbar: 'layoutButton',
        //插件
        plugins: this.allFunctions
          ? "link image media table lists fullscreen quickbars wordcount paste emoticons code autolink autosave imagetools paste preview code"
          : "link image media table lists quickbars wordcount paste emoticons code autolink autosave imagetools paste preview code",
        //工具栏
        toolbar1: this.allFunctions
          ? "code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | fullscreen   indent2em lineheight formatpainter axupimgs | styleselect  fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | table image media charmap  | wordpaster pptimport pdfimport netpaster  emoticons hr pagebreak insertdatetime print preview layoutButton"
          : "undo redo| fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | image media  | wordcount code | wordpaster pptimport pdfimport netpaster  code",

        toolbar_drawer: "sliding",
        //   quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor | wordpaster pptimport pdfimport netpaster",
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
        quickbars_insert_toolbar: "",
        //   contextmenu: "image undo redo copy paste | kityformula-editor",



        //粘贴data格式的图像
        paste_data_images: true,
        width: this.width,
        //   images_dataimg_filter: function (img) {
        //     return img.hasAttribute('internal-blob');
        //   },
        images_upload_handler: (blobInfo, success, failure, progress) => {

          that.editorLoading = true;

          // 方法2：上传
          const xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          xhr.open("POST", API_ROOT + "/protal/tool/upLoadimage");
          xhr.setRequestHeader('accesstoken', sessionStorage.getItem("accessToken"));
          xhr.upload.onprogress = function (e) {
            progress((e.loaded / e.total) * 100);
          };


          console.log(xhr);
          // 成功结果
          xhr.onload = function () {

            if (xhr.status != 200) {
              failure("HTTP Error: " + xhr.status, { remove: true });
              return;
            }
            const json = JSON.parse(xhr.responseText);

            if (json.status != 200) {

              failure("图像上传失败:" + json.msg);
              return;
            }
            that.editorLoading = false;
            success(json.data.address);
          };
          // 失败结果
          xhr.onerror = function () {
            failure("图像上传失败:" + xhr.status);
          };
          // 请求数据
          const formData = new FormData();
          formData.append("file", blobInfo.blob(), blobInfo.filename());
          xhr.send(formData);
        },

      }
    }
  },
  created() {
  },
  methods: {

  },

  watch: {
    //监听内容变化
    value(newValue) {
      this.myValue = newValue
    },
    myValue(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
<style>
.tox-notifications-container {
  display: none;
}

.tox-tinymce-aux {
  z-index: 5000 !important;
}

.tox-statusbar {
  border-top: none !important;
}
</style>
