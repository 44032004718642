import API from "@utils/request";
import { PAGE_SIZE } from "@config";

const ADRESS = "/dictionary/";


/**
 * 获取字典列表（分页）
 * @param {object} params
 * @param {object} pages
 */
export async function getDictionaryList(params, pages) {
  try {

    return await API.get(ADRESS + "list", {
      params: {
        ...params,
        ...pages,
      },
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取字典详情
 * @param {number} id
 */
export async function getDictionaryInfo(id) {
  try {
    return await API.post(ADRESS + "info", {
      id: {
        id,
      },
    });
  } catch (error) {
    return error;
  }
}



/**
 * 编辑字典
 * @param {object} params
 */
export async function editDictionary(params) {
  try {
    return await API.post(ADRESS + "edit", params);
  } catch (error) {
    return error;
  }
}


/**
 * 新增字典
 * @param {object} params
 */
export async function addDictionary(params) {
  try {
    return await API.post(ADRESS + "add", params);
  } catch (error) {
    return error;
  }
}

/**
 * 刪除字典
 * @param {number} id
 */
export async function deleteDictionary(id) {
  try {
    return await API.post(ADRESS + "delete", {
      id: {
        id,
      },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 修改字典狀態
 * @param {number} id
 */
export async function disableDictionary(id, status) {
  try {
    return await API.post(ADRESS + "disable", {
      id: id,
      status: status,
    });
  } catch (error) {
    return error;
  }
}
/**
 * 获取字典键值对
 * @param {object} key
 * @param {object} value
 */
 export async function getDictionaryKeyVal(parentCode) {
  try {
    return await API.post(ADRESS + "getkeyval", {
      parentCode: parentCode
    });
  } catch (error) {
    return error;
  }
}

/**
 * 生成字典缓存文件
 */
 export async function createDictionaryCash() {
  try {
    return await API.post(ADRESS + "createcash", {});
  } catch (error) {
    return error;
  }
}

/**
 * 获取一级字典键值对
 */
 export async function getParentDicKeyVal() {
  try {
    return await API.post(ADRESS + "getparentdickeyVal", {});
  } catch (error) {
    return error;
  }
}


