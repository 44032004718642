/* 布局 */
//前台
import Layout from "@views/layout";

const edueducationRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "edueducation/list",
        name: "EDUEDUCATION",
        meta: {
            title: "教育局表",
        },
        component: () => import("@views/business/edueducation/index.vue"),
    },
        ],
};

export default edueducationRouter;
